/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  Row,
  Col,
  ModalFooter,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { safeDeepGet } from 'iotera-base/utility/json';

class OTPModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: new Array(6).fill(''), // Inisialisasi OTP dengan array kosong
      resend: 0,
    };
  }
  handleResend = () => {
    const { resend } = this.state;
    this.setState({ otp: new Array(6).fill(''), resend: resend + 1 }); // Reset OTP dan countdown
    this.props.clearCountdown(); // Hentikan hitung mundur
    this.props.startCountdown(); // Mulai hitung mundur lagi
    // Di sini Anda bisa menambahkan logika untuk mengirim ulang OTP
    console.log('OTP baru telah dikirim ke WhatsApp/email Anda.'); // Contoh notifikasi
  };

  handleChange = (element, index) => {
    if (isNaN(element.value) || element.value === '') return; // Cegah input non-angka dan input kosong

    const newOtp = [...this.state.otp];
    newOtp[index] = element.value;
    this.setState({ otp: newOtp }, () => {
      // Pindah ke input berikutnya jika ada angka dimasukkan
      if (element.value && element.nextElementSibling) {
        element.nextElementSibling.focus();
      }
    });
  };

  handleKeyDown = (e, index) => {
    const { otp } = this.state;
    if (e.key >= '0' && e.key <= '9') {
      const newOtp = [...otp];
      newOtp[index] = e.key; // Set angka yang dimasukkan ke dalam OTP
      this.setState({ otp: newOtp }, () => {
        // Pindah ke input berikutnya jika angka dimasukkan
        if (newOtp[index] && e.target.nextElementSibling) {
          e.target.nextElementSibling.focus();
        }
      });

      e.preventDefault(); // Cegah perilaku default dari input
    }
    if (e.key === 'Backspace') {
      const newOtp = [...this.state.otp];

      // Jika input saat ini kosong, fokus ke input sebelumnya
      if (!this.state.otp[index] && e.target.previousElementSibling) {
        e.target.previousElementSibling.focus();
      } else {
        if (index > 0) {
          e.target.previousElementSibling.focus();
        }
        // Jika input tidak kosong, reset nilai input tersebut
        newOtp[index] = '';
        this.setState({ otp: newOtp });
      }
    }
  };

  handleSubmit = (e, values) => {
    this.props.clearCountdown();
    const otp = safeDeepGet(values, 'otp', '');
    this.props.handleSendOtp(otp);
    // this.props.handleSendOtp(this.state.otp.join(''));
    this.setState({ otp: otp }); // Reset OTP
  };

  handleClose = () => {
    this.props.clearCountdown();
    this.setState({ otp: '' }); // Reset OTP
    this.props.toggle(); // Panggil fungsi toggle dari props untuk menutup modal
  };

  isSubmitEnabled = () => {
    // Cek jika semua input OTP terisi
    return this.state.otp.every(digit => digit !== '');
  };

  render() {
    const {
      isOpen,
      toggle,
      msg,
      code,
      countdown,
      type,
      sensorEmail,
      sensorNo,
    } = this.props;
    const { resend } = this.state;
    const phoneNumber = localStorage.getItem('phoneNumber');
    const email = localStorage.getItem('email');
    return (
      <Modal isOpen={isOpen} toggle={this.handleClose} centered>
        <AvForm onValidSubmit={this.handleSubmit}>
          <ModalHeader toggle={this.handleClose}>Masukkan OTP</ModalHeader>
          <ModalBody>
            <div className="text-center mb-3">
              {resend <= 5 ? (
                <p>
                  Kami telah mengirimkan kode OTP ke akun WhatsApp Anda. Silakan
                  periksa pesan WhatsApp yang dikirim ke nomor{' '}
                  {sensorNo(phoneNumber)}. Jika dalam waktu 5 menit Anda belum
                  menerima pesan di WhatsApp, mohon cek kotak masuk atau folder
                  spam pada email {sensorEmail(email)} untuk mendapatkan
                  instruksi lebih lanjut dan melanjutkan proses verifikasi.
                </p>
              ) : (
                <p>Telah mencapai batas, silakan hubungi IOTERA.</p>
              )}

              {countdown > 0 && (
                <span>
                  Hitung mundur: {countdown} detik, Resend {resend} / 5
                </span>
              )}
            </div>
            <div>
              <AvField
                type="text"
                id="otp"
                value={''}
                name="otp"
                placeholder="Masukan otp"
                className="form-control"
                validate={{
                  required: { value: true },
                }}
              />
            </div>
            {/* <div className="otp-container">
            {this.state.otp.map((data, index) => (
              <Input
                key={index}
                type="text"
                maxLength="1"
                className="otp-input"
                value={data}
                // onChange={e => this.handleChange(e.target, index)}
                onKeyDown={e => this.handleKeyDown(e, index)}
                onFocus={e => e.target.select()}
              />
            ))}
          </div> */}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between align-items-center">
            {' '}
            {/* Flexbox untuk posisi */}
            <div className="text-start"> Server Code: {code}</div>
            <div className="text-end">
              <Button
                color="primary"
                // onClick={this.handleSubmit}
                // disabled={!this.isSubmitEnabled()} // Nonaktifkan tombol jika OTP belum terisi lengkap atau waktu habis
              >
                Verifikasi
              </Button>
              <div className="d-inline-block ms-2">
                <Button
                  color="secondary"
                  onClick={this.handleResend}
                  disabled={countdown !== 0 || resend >= 5}
                >
                  Kirim Ulang
                </Button>
              </div>
            </div>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

export default OTPModal;
